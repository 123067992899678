class StateStorage {
    controls: {
        closeBtn?: HTMLElement;
    };
    content: {
        base?: HTMLElement;
        sendSuccess?: HTMLElement;
    };

    constructor() {
        this.content = {
            base: document.querySelector('#js-contact-us-content-base'),
            sendSuccess: document.querySelector(
                '#js-contact-us-content-success'
            )
        };
        this.controls = {
            closeBtn: document.querySelector(
                '#contactUsModal div[aria-label="Close modal"]'
            )
        };
    }

    setBaseState() {
        this.controls.closeBtn.style.display = 'block';
        this.content.base.style.display = 'block';
        this.content.sendSuccess.style.display = 'none';
    }

    setSendSuccesState() {
        this.controls.closeBtn.style.display = 'none';
        this.content.base.style.display = 'none';
        this.content.sendSuccess.style.display = 'flex';
    }
}

export default StateStorage;
