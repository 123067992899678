import initModal, {ModalProps} from '@kit/Modal';
import StateStorage from './helpers/stateStorage';
import $ from 'jquery';

import './ModalContactUs.scss';

const CONTACT_US_MODAL_ID = 'contactUsModal';
const CONTACT_US_MODAL_TARGET = 'contactUsModal-target';

type ContactUsModalProps = {
    triggerSelector?: string;
    formSelectorId?: string;
};

const ModalContactUs = ({triggerSelector, formSelectorId}: ContactUsModalProps) => {
    const props: ModalProps = {
        modalId: CONTACT_US_MODAL_ID,
        targetId: CONTACT_US_MODAL_TARGET,
        triggerSelector,
        className: 'contact-us-modal'
    };

    window.addEventListener('load', () => {
        const onCloseHandler = () => {
            stateStorage.setBaseState();
        };

        initModal({
            onCloseHandler,
            ...props
        });
        const stateStorage = new StateStorage();

        $(formSelectorId).on('submit', (event) => {
            event.preventDefault();
            const form = event.target as HTMLFormElement;
            $.ajax({
                method: 'POST',
                url: new URL(form.action),
                data: $(event.target).serialize(),
                success: () => {
                    form.reset();
                    stateStorage.setSendSuccesState();
                },
            })

            return false;
        })
    });
};

export default ModalContactUs;
